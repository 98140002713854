;(function($){

	function Slides() {
		var _ =  this;

		$('.crossfade').slick({
			dots: false,
			arrows: false,
			infinite: true,
			speed: 5000,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500
		});
		
	}
	
	new Slides();

}(jQuery));




